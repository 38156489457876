<template>
    <div class="shopOperation">
        <div class="header">
            <CountDown/>
            <div class="header-right">
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userIcon ? userIcon : require('./../../../assets/images/user_img.png')" alt="" />
                    </div>
                    <div class="user-name">{{username}}</div>
                </div>
                <div class="exit-btn" @click="studentLogout">
                    <i class="iconfont">&#xe642;</i>
                    <span>返回主页面</span>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="content-box">
                <div class="content-left">
                    <img src="./../../../assets/images/shopOperationbj.png" alt="">
                </div>
                <div class="content-right">
                    <span>店铺运营分析答辩</span>
                    <span>本模块分两部分:<br>第一部分PPT制作，选手根据参加竞赛系统店铺数据分析与运营推广得出的店铺经营数据为依据，从选品定位、店铺营销策略、店铺引流、店铺运营效果分析等环节制作PPT，制作完成后通过竞赛系统提交；第二部分PPT现场</span>
                    <div class="ppt-content">
                        <el-button type="primary" round @click="uploadEvent">上传文件</el-button>
                        <div class="file_list" v-if="fileName">
                            <div class="ppt-file-icon">
                                <i class="iconfont" v-html="extShow"></i>
                                <span class="file-name">{{fileName}}</span>
                            </div>
                            <div class="upload-suc-icon"><i class="iconfont">&#xe730;</i></div>
                        </div>
                        <input
                            ref="modelInput"
                            type="file"
                            id="uploadInput"
                            @change="uploadFile($event)"
                            style="display:none"
                            accept=".ppt,.pptx,.mp4"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CountDown from '../../../components/student/CountDown.vue'
    export default {
        name: "Index",
        components: {
            CountDown
        },
        data(){
            return {
                userIcon: '',
                username:'',
                fileName: "mp4.mp4"
            }
        },
        computed: {
            extShow() {
                let fileExts = this.fileName.split(".")
                const jsonExt = {
                    "mp4": "&#xe675;",
                    "ppt": "&#xe63e;",
                    "pptx": "&#xe63e;"
                }
                return jsonExt[fileExts[fileExts.length - 1]]
            }
        },
        mounted () {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            if (this.username === null) {
                this.$message({
                    type: 'warning',
                    message: '登录信息失效，请重新登录',
                    onClose: () => {
                        this.$router.go(-1);
                    }
                });
            }
        },
        created() {
            this.initInfo();
            // this.luyanInfo();
        },
        methods: {
            initInfo() {
                this.$http.axiosGet(this.$api.lyInfo, (res) => {
                    if (res.code === 200) {
                        if (res.data) {
                            this.fileName = res.data.file_name;
                        }
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
            },
            studentLogout() {
                this.$router.go(-1)
            },
            uploadFile(event){
                let fileName = event.target.files[0].name;
                let limiteFileType = ["ppt", "pptx","mp4"];
                if (!limiteFileType.includes(fileName.split(".")[fileName.split(".").length - 1].toLowerCase())){
                    this.$message.warning("文件格式错误！");
                    return;
                }
                let file = event.target.files[0];
                let formData = new FormData();
                formData.append('file',file);
                this.$http.axiosPost(this.$api.uploadLuyanFile, formData, res=>{
                    if(res.code === 200){
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.fileName = fileName;
                            }
                        });
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            uploadEvent() {
                this.$refs.modelInput.click();
            }
        }
    }
</script>

<style scoped lang="scss">
    .shopOperation{
        .header{
            line-height: 78px;
            border: 1px solid #D1D2DB;
            text-align: center;
            position: relative;
            /deep/.count-down{
                span{
                    color: #FD4446;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute;
                right: 30px;
                top: 10px;
                line-height: 58px;
                .user-info {
                    display: flex;
                    align-items: center;
                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .user-name {
                        margin-left: 6px;
                    }
                }
                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    .iconfont {
                        font-size: 20px;
                    }
                    span {
                        margin-left: 6px;
                    }
                    &:hover {
                        color: #1122D8;
                    }
                }
            }
        }
        .content{
            width: 100%;
            height: calc(100vh - 81px);
            background: linear-gradient(0deg, #4481EB, #04BEFE);
            display: flex;
            align-items: center;
            justify-content: center;
            .content-box{
                display: flex;
                /*align-items: center;*/
                width: calc(100vw - 230px);
                height: calc(100vh - 200px);
                .content-left{
                    width: 50%;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .content-right{
                    width: 50%;
                    height: 100%;
                    background: #ffffff;
                    display: flex;
                    flex-direction: column;
                    border-radius: 0 20px 20px 0;
                    span:nth-child(1){
                        color: #2F95F1;
                        font-size: 48px;
                        text-align: center;
                        padding-top: 13%;
                    }
                    span:nth-child(2){
                        padding-right: 40px;
                        padding-left: 40px;
                        color: #666666;
                        font-size: 24px;
                        line-height: 146%;
                        padding-top: 7%;
                    }
                    .ppt-content {
                        margin-top: 50px;
                        padding: 0 85px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .file_list {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-left: 20px;
                            .ppt-file-icon{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                i.iconfont {
                                    font-size: 28px;
                                    color: #C84724;
                                    margin-right: 5px;
                                }
                                span.file-name {
                                    padding: 0;
                                    margin: 0;
                                    font-size: 16px;
                                    color: #333333;
                                }
                            }
                            .upload-suc-icon {
                                padding-left: 20px;
                                i.iconfont {
                                    font-size: 16px;
                                    color: #67C23A;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

</style>